import { useState, useEffect } from 'react';
import { m } from 'framer-motion';
import Logo from './Logo';

// ----------------------------------------------------------------------

export default function ScreenLoading() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className="right-0 w-4 bottom-0 h-4 z-[9998] flex absolute items-center justify-center bg-pink-brand-muted">
      <div>
        <m.div
          animate={{
            scale: [1, 0.9, 0.9, 1, 1],
            opacity: [1, 0.48, 0.48, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Logo disabledLink sx={{ width: 64, height: 64 }} />
        </m.div>

        <m.div
          animate={{
            scale: [1.6, 1, 1, 1.6, 1.6],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
          className="w-[100px] h-[100px] min-w-[100px] absolute border-[3px] border-primary"
        />

        <m.div
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{
            ease: 'linear',
            duration: 3.2,
            repeat: Infinity,
          }}
          className="w-[120px] h-[120px] min-w-[120px] absolute border-[8px] border-primary"
        />
      </div>
    </div>
  );
}
